#projectEstimatorTable th {
  font-weight: normal;
}

#projectEstimatorTable td,
#projectEstimatorTable th {
  border-bottom: 1px solid rgb(223 228 228);
}

#projectEstimatorTable td,
#projectEstimatorTable th {
  padding: 10px 8px 8px 12px;
}

/* #projectEstimatorTable .highlighted-row {
  background-color: rgb(0 89 132);
  color: white;
} */

/* #projectEstimatorTable tr:not(.highlighted-row) :nth-child(even) {
  background-color: rgb(242 244 244);
} */
